import Component from '../component';

/**
 * # Modal
 * Open a modal box, based upon the bootstrap modal.
 *
 * | setting 			| type 		| description 											|
 * |====================|===========|=======================================================|
 * |`title`				| string	| Title of the Modal popup								|
 * |`body`				| string	| Content in the middle of the popup, this can be HTML 	|
 * |`closeButtonText`	| string	| The text to show on the close button 				 	|
 * |`actionButtonText`	| string	| The text to show on the action button 				|
 * |`onClose`			| function 	| What to do on close 									|
 * |`onAction`			| function 	| What to do when the action button is clicked			|
 * |`showFooter`		| bool		| Show the footer or not, defaults to `true`			|
 */
class Modal extends Component {
	/**
	 * @see  class description for options
	 * @param  {object} settings
	 * @return {Modal} Returns Modal object
	 */
	constructor(settings) {
		super();
		this.settings = {
			title: '',
			body: '',
			colourId: '',
			closeButtonText: 'Close',
			actionButtonText: 'Log in',
			onClose: (e) => {
				const event = new CustomEvent('close-modal', {
					bubbles: true,
					detail: settings.id ? settings.id : ''
				});
				// Dispatch the event.
				document.dispatchEvent(event);
			},
			onAction: () => {},
			containsError: false,
			showFooter: true,
			containsForm: false,
			id: '',
			...settings
		};
		// get the template
		this.render();
	}

	/**
	 * # ExitKeyCode
	 * Returns the list of keycodes which are consided to be for exiting the modal
	 * @return {array}
	 */
	_exitKeyCodes() {
		// 27 is the ESC key
		return [27];
	}

	/**
	 * # Template
	 * Sets up the HTML for the template, we are using the bootstrap HTML for this
	 * 
	 * @private
	 * @return {[type]} [description]
	 */
	_template() {
		return `
			<div class="modal fade in show" tabindex="-1" role="dialog" style="display: block;">
				<div class="modal-dialog modal-dialog-centered ${this.settings.colourId} ${this.settings.largeModal ? 'modal-lg' : ''}" role="document">
					<div class="modal-content">
						${this.settings.containsForm ? '<form>' : ''}
						<div class="modal-header">
							<div class="title">
								<h3 class="modal-title">${this.settings.title}</h3>
							</div>
							<div class="close-container">
								<button type="button" class="close" aria-label="Close">
									<span aria-hidden="true">x</span>
								</button>
							</div>
						</div>
						<div class="modal-body">
						${this.settings.containsError ? '<div class="alert alert-danger">' : ''}	
								${this.settings.body}
							${this.settings.containsError ? '</div>' : ''}
						</div>
						${this.settings.showFooter ? `
						<div class="modal-footer">
						    <button type="button" class="cancel btn btn-primary outline">${this.settings.closeButtonText}</button>
							<button type="${this.settings.containsForm ? 'submit' : 'button'}" class="btn btn-primary submitBtn">${this.settings.actionButtonText}</button>	
						</div>
						` : ``}
						${this.settings.containsForm ? '</form>' : ''}
					</div>
				</div>
			</div>
		`;
	}

	/**
	 * # Render
	 * Build the modal popup, this won't open the popup. To open the popup make
	 * sure you call `open`.
	 */
	render() {
		// create an empty div for the element
		this.element = document.createElement('div');
		this.el.id = this.settings.id;
		this.el.classList.add('overlay', 'fade', 'in', 'show');
		this.el.innerHTML = this._template();
		// bind all the events for the modal
		this.addEvent(this.el.querySelector('.close'), 'click', (e) => {
			e.preventDefault();
			this.settings.onClose(e);
			this.close();
		});
  
		if (this.el.querySelector('.cancel')) {
			this.addEvent(this.el.querySelector('.cancel'), 'click', (e) => {
				e.preventDefault();
				this.settings.onClose(e);
				this.close();
			});
		}

		if (this.settings.containsForm) {
			this.addEvent(this.el.querySelector('form'), 'submit', this.settings.onAction.bind(this));
		}

		this.addEvent(this.el, 'keydown', (e) => {
			if (this._exitKeyCodes().indexOf(e.keyCode) !== -1) {
				this.settings.onClose(e);
				this.close();
				if(document.getElementById('hsbc-card-overlay') == null){
					this.close();
				}
			}
		});
	}

	update(body){
		this.settings.body = body;
		this.el.innerHTML = this._template();
	}

	/**
	 * # Open
	 * Open the modal box
	 */
	open() {
		document.body.appendChild(this.el);
	}

	/**
	 * # Close
	 * Close the modal box
	 */

	 closeOut() {
		if (this.el) {
			var element = document.getElementsByClassName('tastingNote-modal');
			$(element).remove();
		}
	 }

	close() {
		if(this.el.parentNode){
			this.el.parentNode.removeChild(this.el);
		}
	}
};

export default Modal;